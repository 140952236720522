import React from 'react';
import PropTypes from 'prop-types';

export const Pin = ({ size }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    style={{ transform: `translate(${-size / 2}px,${-size}px)` }}
  >
    <g>
      <path
        stroke="null"
        id="svg_2"
        fill="#e74c3c"
        d="m12,0.73684c-3.90477,0 -7.07018,3.1209 -7.07018,6.97076c0,1.23818 0.33725,2.3962 0.91135,3.40348c0.09536,0.16729 0.19531,0.33198 0.30384,0.49056l5.85499,10.04748l5.85499,-10.04748c0.09014,-0.13157 0.16792,-0.27099 0.24834,-0.40866l0.05568,-0.0819c0.57357,-1.00728 0.91117,-2.1653 0.91117,-3.40348c0,-3.84986 -3.16567,-6.97076 -7.07018,-6.97076zm0,3.48538c1.95225,0 3.53509,1.56049 3.53509,3.48538c0,1.9248 -1.58284,3.48538 -3.53509,3.48538c-1.95234,0 -3.53509,-1.56058 -3.53509,-3.48538c0,-1.92489 1.58275,-3.48538 3.53509,-3.48538z"
      />
      <path
        stroke="null"
        id="svg_3"
        fill="#c0392b"
        d="m12,3.31579c-2.49011,0 -4.50877,2.01867 -4.50877,4.50877c0,2.48975 2.01866,4.50877 4.50877,4.50877c2.48974,0 4.50877,-2.01902 4.50877,-4.50877c0,-2.4901 -2.01903,-4.50877 -4.50877,-4.50877zm0,1.80351c1.49421,0 2.70526,1.21115 2.70526,2.70526s-1.21105,2.70527 -2.70526,2.70527s-2.70526,-1.21115 -2.70526,-2.70527s1.21105,-2.70526 2.70526,-2.70526z"
      />
    </g>
  </svg>
);

export const Maximize = props => {
  const { color, size, ...otherProps } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      {...otherProps}
    >
      <path d="M8 3H5a2 2 0 0 0-2 2v3m18 0V5a2 2 0 0 0-2-2h-3m0 18h3a2 2 0 0 0 2-2v-3M3 16v3a2 2 0 0 0 2 2h3" />
    </svg>
  );
};

Maximize.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

Maximize.defaultProps = {
  color: 'currentColor',
  size: '24'
};

export const Minimize = props => {
  const { color, size, ...otherProps } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      {...otherProps}
    >
      <path d="M8 3v3a2 2 0 0 1-2 2H3m18 0h-3a2 2 0 0 1-2-2V3m0 18v-3a2 2 0 0 1 2-2h3M3 16h3a2 2 0 0 1 2 2v3" />
    </svg>
  );
};

Minimize.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

Minimize.defaultProps = {
  color: 'currentColor',
  size: '24'
};
