import React, { useState, useContext } from 'react';
import ReactMapGL, { Marker } from 'react-map-gl';
import ThemeContext from '../../../store/createContext';
import { Pin } from './Icons';
import NavControl from './NavControl';
import 'mapbox-gl/dist/mapbox-gl.css';

const MAPBOX_TOKEN =
  'pk.eyJ1IjoibHVjYXN3b2oiLCJhIjoiY2l5Nmg4cWU1MDA0ejMzcDJtNHJmZzJkcyJ9.WhcEdTYQH6sSw2pm0RSP9Q';

const Mapbox = ({ fullScreen, goFull, lat, lon }) => {
  const [viewport, setViewport] = useState({
    latitude: lat,
    longitude: lon,
    zoom: 15
  });
  const { themeStyle } = useContext(ThemeContext);

  const onViewportChange = viewportData => setViewport(viewportData);
  return (
    <ReactMapGL
      {...viewport}
      mapboxApiAccessToken={MAPBOX_TOKEN}
      width="100%"
      height="100%"
      maxZoom={17.5}
      scrollZoom={fullScreen}
      mapStyle={`mapbox://styles/mapbox/${themeStyle}-v9`}
      onViewportChange={onViewportChange}
    >
      <Marker latitude={lat} longitude={lon}>
        <Pin size={40} />
      </Marker>
      <NavControl
        onViewportChange={onViewportChange}
        goFull={goFull}
        fullScreen={fullScreen}
      />
    </ReactMapGL>
  );
};

export default Mapbox;
