import React from 'react';
import { NavigationControl } from 'react-map-gl';
import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { withTheme } from 'emotion-theming';
import { Maximize, Minimize } from './Icons';

const topLeft = css`
  padding: 1rem 0 0 0.75rem;
`;
const bottomLeft = css`
  padding: 0 0 2rem 0.5rem;
  bottom: 0;
`;
const topRight = css`
  padding: 1rem 0.6rem 0 0;
  top: 0;
  right: 0;
`;
const Position = styled.div`
  position: absolute;
  ${props =>
    (props.topLeft && topLeft) ||
    (props.bottomLeft && bottomLeft) ||
    (props.topRight && topRight)};
`;
const FScreenBtn = styled.button`
  outline: none;
  border: 0;
  background-color: transparent;
  cursor: pointer;
`;

const NavControl = ({ onViewportChange, goFull, fullScreen, theme }) => (
  <>
    <Position topLeft>
      <NavigationControl
        showCompass={false}
        onViewportChange={onViewportChange}
      />
    </Position>
    <Position topRight>
      <FScreenBtn type="button" title="Fullscreen" onClick={goFull}>
        {fullScreen ? (
          <Minimize size={20} color={theme.activeLink} />
        ) : (
          <Maximize size={20} color={theme.activeLink} />
        )}
      </FScreenBtn>
    </Position>
  </>
);

export default withTheme(NavControl);
