import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { graphql, navigate } from 'gatsby';
import Fullscreen from 'react-full-screen';
import { Layout, SEO, ContactInfo } from '../components/common';
import Mapbox from '../components/contact/Mapbox';
import website from '../../config/website';

function encode(data) {
  return Object.keys(data)
    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
    .join('&');
}

export class ContactPageTemplate extends Component {
  state = {
    isValidated: false,
    isFull: false
  };

  goFull = () => {
    this.setState(prevState => ({ isFull: !prevState.isFull }));
  };

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = e => {
    e.preventDefault();
    const form = e.target;
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...this.state
      })
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch(error => alert(error));
  };

  onChange = isFull => this.setState({ isFull });

  render() {
    const { isFull } = this.state;
    const { title, phone, email, street, city, latitude, longitude } = this.props;

    return (
      <>
        <Fullscreen enabled={isFull} onChange={this.onChange}>
          <div className="full-width-map-container full-screenable-node">
            <Mapbox
              goFull={this.goFull}
              fullScreen={isFull}
              lat={latitude * 1}
              lon={longitude * 1}
            />
          </div>
        </Fullscreen>
        <section className="section">
          <div className="container">
            <div className="columns">
              <div className="column">
                <section className="section">
                  <div className="content">
                    <div className="column">
                      <ContactInfo
                        title={title}
                        phone={phone}
                        email={email}
                        street={street}
                        city={city}
                      />
                    </div>
                  </div>
                </section>
              </div>
              <div className="column ">
                <section className="section">
                  <form
                    name="contact"
                    method="post"
                    action="/"
                    data-netlify="true"
                    data-netlify-honeypot="bot-field"
                    onSubmit={this.handleSubmit}
                  >
                    {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
                    <input type="hidden" name="form-name" value="contact" />
                    <div hidden>
                      <label>
                        Don’t fill this out: <input name="bot-field" onChange={this.handleChange} />
                      </label>
                    </div>
                    <div className="field">
                      <label className="label" htmlFor="name">
                        Ihr Name
                      </label>
                      <div className="control">
                        <input
                          className="input"
                          type="text"
                          name="name"
                          onChange={this.handleChange}
                          id="name"
                          required
                        />
                      </div>
                    </div>
                    <div className="field">
                      <label className="label" htmlFor="email">
                        Ihre E-Mail-Adresse
                      </label>
                      <div className="control">
                        <input
                          className="input"
                          type="email"
                          name="email"
                          onChange={this.handleChange}
                          id="email"
                          required
                        />
                      </div>
                    </div>
                    <div className="field">
                      <label className="label" htmlFor="message">
                        Ihre Nachricht
                      </label>
                      <div className="control">
                        <textarea
                          className="textarea"
                          name="message"
                          onChange={this.handleChange}
                          id="message"
                          required
                        />
                      </div>
                    </div>
                    <div className="field">
                      <button className="button is-link" type="submit">
                        Senden
                      </button>
                    </div>
                  </form>
                </section>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

ContactPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  street: PropTypes.string.isRequired,
  city: PropTypes.string.isRequired,
  latitude: PropTypes.string.isRequired,
  longitude: PropTypes.string.isRequired
};

const ContactPage = ({
  data: {
    markdownRemark: { frontmatter }
  },
  location
}) => (
  <Layout customSEO>
    <SEO title={`${frontmatter.title} | ${website.siteTitle}`} pathname={location.pathname} />
    <ContactPageTemplate
      title={frontmatter.title}
      phone={frontmatter.phone}
      email={frontmatter.email}
      street={frontmatter.address.street}
      city={frontmatter.address.city}
      latitude={frontmatter.coordinates.latitude}
      longitude={frontmatter.coordinates.longitude}
    />
  </Layout>
);

ContactPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object
  }).isRequired
};

export default ContactPage;

export const contactPageQuery = graphql`
  query ContactPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        phone
        email
        address {
          street
          city
        }
        coordinates {
          latitude
          longitude
        }
      }
    }
  }
`;
